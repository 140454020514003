import React, { useEffect, useState } from "react";

/* Third party */
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { Helmet } from "react-helmet";

/* Components */
import ChartsComp from "../../../components/OrderPageComp/ChartsComp/ChartsComp";
import TableComp from "../../../components/OrderPageComp/TableComp/TableComp";

/* MUI */
import FormControl from "@mui/material/FormControl";

/* Icons */
import {
	useFilterOrderServiceCouponMutation,
	useGetWebsiteOrdersQuery,
	useSearchInWebsiteOrdersMutation,
} from "../../../RTK/apiSlices/websiteOrdersApi";
import { AiOutlineSearch } from "react-icons/ai";
import { MenuItem, Select } from "@mui/material";
import { IoIosArrowDown } from "react-icons/io";
import { useSelectCouponsQuery } from "../../../RTK/apiSlices/selectorApi";
import Button from "../../../UI/Button/Button";
import moment from "moment";

const OrdersPage = () => {
	const [page, setPage] = useState(1);
	const [search, setSearch] = useState("");
	const [coupon, setCoupon] = useState("");
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [searchResults, setSearchResults] = useState([]);
	const [filterResults, setFilterResults] = useState([]);
	const { data: selectCoupon } = useSelectCouponsQuery();

	const { data: fetchedData, isLoading: loading } = useGetWebsiteOrdersQuery({
		page,
		number: rowsPerPage,
	});

	// handle search
	const [searchInWebsiteOrders, { isLoading: searchIsLoading }] =
		useSearchInWebsiteOrdersMutation();

	let resultData = fetchedData?.Websiteorder || [];
	if (search !== "") {
		resultData = searchResults?.orders;
	} else if (coupon !== "") {
		resultData = filterResults?.Websiteorder;
	} else {
		resultData = fetchedData?.Websiteorder || [];
	}

	useEffect(() => {
		const debounce = setTimeout(() => {
			if (search) {
				const fetchData = async () => {
					try {
						const response = await searchInWebsiteOrders({
							query: search,
						});

						setSearchResults(response.data.data);
					} catch (error) {
						console.error("Error fetching orders:", error);
					}
				};

				fetchData();
			} else {
				setSearchResults(resultData);
			}
		}, 500);
		return () => {
			clearTimeout(debounce);
		};
	}, [search]);

	// handle filter
	const [filterOrderServiceCoupon, { isLoading: filterIsLoading }] =
		useFilterOrderServiceCouponMutation();

	useEffect(() => {
		const debounce = setTimeout(() => {
			if (coupon) {
				const fetchData = async () => {
					try {
						const response = await filterOrderServiceCoupon({
							coupon,
						});

						setFilterResults(response.data.data);
					} catch (error) {
						console.error("Error fetching orders:", error);
					}
				};

				fetchData();
			} else {
				console.log("data outside fetch", resultData);

				setFilterResults(resultData);
			}
		}, 500);
		return () => {
			clearTimeout(debounce);
		};
	}, [coupon]);

	// handle export file
	const fileType =
		"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
	const fileExtension = ".xlsx";
	const exportToCSV = () => {
		let totalBeforeDiscount = 0;
		let totalAfterDiscount = 0;

		const data = resultData?.map((item) => {
			totalBeforeDiscount += parseFloat(item?.total_price) || 0;
			totalAfterDiscount += parseFloat(item?.discount_value) || 0;

			return {
				"تاريخ الطلب": moment(item?.created_at).format("YYYY-MM-DD"),
				"اسم الكوبون": item?.coupon?.code,
				"تاريخ انتهاء الكوبون": moment(item?.expire_date).format("YYYY-MM-DD"),
				"رقم الطلب": item?.order_number,
				"قيمة الطلب قبل الخصم": item?.total_price?.toFixed(2),
				"نوع الخصم": item?.coupon?.discount_type,
				"قيمة الطلب بعد الخصم": item?.discount_value?.toFixed(2),
				"حالة الدفع":
					item?.payment_status === "pending"
						? "قيد الدفع"
						: item?.payment_status === "paid"
						? "تم الدفع"
						: item?.payment_status === "failed"
						? "فشل الدفع"
						: null,
			};
		});

		const ws = XLSX.utils.json_to_sheet(data);

		// Add 5 empty rows
		const lastRow = XLSX.utils.decode_range(ws["!ref"]).e.r;
		for (let i = 1; i <= 5; i++) {
			XLSX.utils.sheet_add_aoa(ws, [[]], { origin: lastRow + i });
		}

		// Add the summary row
		const summaryRow = [
			"المجموع",
			"",
			"",
			"",
			totalBeforeDiscount.toFixed(2),
			"",
			totalAfterDiscount.toFixed(2),
		];
		XLSX.utils.sheet_add_aoa(ws, [summaryRow], { origin: lastRow + 6 });

		// Style the summary row
		const range = XLSX.utils.decode_range(ws["!ref"]);
		for (let C = range.s.c; C <= range.e.c; C++) {
			const addr = XLSX.utils.encode_cell({ r: lastRow + 6, c: C });
			if (!ws[addr]) ws[addr] = { t: "s", v: "" };
			ws[addr].s = {
				fill: { fgColor: { rgb: "FFFF0000" } }, // Red background
				font: { color: { rgb: "FFFFFFFF" } }, // White text
				alignment: { horizontal: "center", vertical: "center" },
			};
		}

		// Center align all cells
		for (let i in ws) {
			if (typeof ws[i] != "object") continue;
			if (!ws[i].s) ws[i].s = {};
			ws[i].s.alignment = {
				vertical: "center",
				horizontal: "center",
				wrapText: true,
			};
		}

		const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
		const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
		const fileData = new Blob([excelBuffer], { type: fileType });
		FileSaver.saveAs(fileData, `${coupon}` + fileExtension);
	};

	return (
		<>
			<Helmet>
				<title>لوحة تحكم أطلبها | الطلبات</title>
			</Helmet>
			<div
				className='relative p-4 2xl:pl-36 h-fit xl:pr-8'
				style={{ backgroundColor: "#F7F7F7" }}>
				{/**  order charts  **/}
				<ChartsComp fetchedData={fetchedData} loading={loading} />

				<div className={"mt-8"} style={{ backgroundColor: "#FFF" }}>
					<div className='p-0 md:p-4'>
						<div className='flex flex-col items-start justify-between p-3 mb-4  gap-y-4 md:p-0 w-full'>
							<h2
								className={
									"font-bold md:text-2xl text-[18px] whitespace-nowrap mb-3"
								}
								style={{ color: "#011723" }}>
								جدول الطلبات
							</h2>

							{/** search order input */}
							<div className='flex flex-col md:flex-row w-full gap-4'>
								<FormControl
									className='flex flex-col w-full '
									sx={{ maxWidth: "100%", flex: "1" }}>
									<h2 className=' text-lg font-medium'>
										البحث عن طريق رقم الطلب
									</h2>
									<label className={`w-full flex-1 md:h-14 h-[45px] relative `}>
										<input
											className='h-14 outline-0 pr-10 rounded-lg bg-[#FFFFFF00] w-full'
											placeholder=' ادخل رقم الطلب'
											type='text'
											name='name'
											value={search}
											onChange={(e) => setSearch(e.target.value)}
											style={{
												maxWidth: "100%",
												border: "1px solid #A7A7A7",
											}}
										/>
										<div
											className={`absolute top-1/2 right-4 -translate-y-2/4`}>
											<AiOutlineSearch
												color='#B6BE34'
												size={"18px"}></AiOutlineSearch>
										</div>
									</label>
								</FormControl>

								<FormControl
									className='flex flex-col  w-full '
									sx={{ maxWidth: "100%", flex: "1" }}>
									<h2 className=' text-lg font-medium'>
										فرز الطلبات حسب الكوبون
									</h2>
									<Select
										className='text-lg font-normal'
										IconComponent={() => {
											return (
												<IoIosArrowDown
													className='duration-200 select_arrow'
													size={"1.2rem"}
												/>
											);
										}}
										value={coupon}
										onChange={(e) => {
											setCoupon(e.target.value);
										}}
										displayEmpty
										renderValue={(selected) => {
											if (coupon === "") {
												return <h2>اختر الكوبون</h2>;
											}
											return selected;
										}}
										sx={{
											height: "3.5rem",
											pl: "0.5rem",
											borderRadius: "8px",
											border: "1px solid #B6BE34",
											"& .MuiOutlinedInput-notchedOutline": {
												border: "none",
												paddingRight: "10px",
											},
										}}>
										<MenuItem
											className='souq_storge_category_filter_items'
											sx={{
												backgroundColor: "rgba(255, 255, 255, 0)",
												height: "3rem",
												"&:hover": {
													backgroundColor: "rgba(242, 245, 207, 1)",
												},
											}}
											value={"الكل"}>
											الكل
										</MenuItem>
										{selectCoupon?.map((item, idx) => {
											return (
												<MenuItem
													key={idx}
													className='souq_storge_category_filter_items'
													sx={{
														backgroundColor: "rgba(255, 255, 255, 0)",
														height: "3rem",
														"&:hover": {
															backgroundColor: "rgba(242, 245, 207, 1)",
														},
													}}
													value={`${item?.code}`}>
													{item?.code}
												</MenuItem>
											);
										})}
									</Select>
								</FormControl>

								{coupon !== "" &&
								coupon !== "الكل" &&
								filterResults?.Websiteorder?.length > 0 ? (
									<FormControl>
										<h2 className=' text-lg font-medium'>
											تصدير طلبات هذا الكوبون
										</h2>
										<Button
											onClick={exportToCSV}
											style={{ backgroundColor: "rgb(180,237,238)" }}
											textStyle={{ color: "#03787A" }}
											className={
												"w-full md:h-[56px] h-[45px] md:text-[18px] text-[16px]"
											}
											type='normal'>
											تصدير ملف
										</Button>
									</FormControl>
								) : null}
							</div>
						</div>
						{/** Orders Table  */}
						<div dir={"ltr"}>
							<TableComp
								fetchedData={resultData || []}
								loading={loading || searchIsLoading || filterIsLoading}
								pageNumber={page}
								setPageNumber={setPage}
								currentPage={rowsPerPage}
								setCurrentPage={setRowsPerPage}
								page_count={fetchedData?.page_count}
								search={search}
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default OrdersPage;
