import React, { useState, useContext } from "react";
import styles from "./MyAccount.module.css";
/* Third party */
import { Helmet } from "react-helmet";
import ImageUploading from "react-images-uploading";
import { toast } from "react-toastify";
/* Custom Hook */
import { useForm } from "react-hook-form";

/* Components */
import { LoadingContext } from "../../store/LoadingProvider";
import CircularLoading from "../../UI/CircularLoading/CircularLoading";

/* Custom Button */
import Button from "../../UI/Button/Button";

/* Icons */
import { HiOutlineMail } from "react-icons/hi";
import { IoIosCall } from "react-icons/io";
import {
	UploadOutlined,
	PasswordClose,
	PasswordOpen,
} from "../../assets/Icons";

// RTK Query
import { useEditProfileDataMutation } from "../../RTK/apiSlices/profileApi";

const BackDrop = ({ onClick }) => {
	return (
		<div
			onClick={onClick}
			className={`fixed back_drop bottom-0 left-0  w-full bg-slate-900  z-10 ${styles.back_drop}`}
			style={{ height: "calc(100% - 5rem)" }}></div>
	);
};

const MyAccount = ({
	cancel,
	user,
	loading,

	edit,
	setEditUser,
}) => {
	const token = localStorage.getItem("token");

	const LoadingStore = useContext(LoadingContext);
	const { setLoadingTitle } = LoadingStore;
	const [showPassword, setShowPassword] = React.useState(false);
	const [showPasswordConfirm, setShowPasswordConfirm] = React.useState(false);
	const {
		register,
		handleSubmit,

		formState: { errors },
	} = useForm({
		mode: "onBlur",
		defaultValues: {
			name: user?.name,
			email: user?.email,
			phonenumber: user?.phonenumber?.startsWith("+966")
				? user?.phonenumber?.slice(4)
				: user?.phonenumber?.startsWith("00966")
				? user?.phonenumber.slice(5)
				: user?.phonenumber,
			image: user?.image,
			user_name: user?.user_name,
			password: user?.password,
			confirm_password: user?.confirm_password,
			user_type: user?.role?.name,
		},
	});
	const [DataError, setDataError] = useState({
		name: "",
		email: "",
		phonenumber: "",
		image: "",
		user_name: "",
		password: "",
		confirm_password: "",
		user_type: "",
	});
	const resetData = () => {
		setDataError({
			name: "",
			email: "",
			phonenumber: "",
			image: "",
			user_name: "",
			password: "",
			confirm_password: "",
			user_type: "",
		});
	};
	const [images, setImages] = useState([]);
	// handle images size
	const maxFileSize = 1 * 1024 * 1024; // 1 MB;
	const onChangeImage = (imageList) => {
		// Check image size before updating state
		const isSizeValid = imageList.every(
			(image) => image.file.size <= maxFileSize
		);

		if (!isSizeValid) {
			setDataError({
				...DataError,
				image: " حجم الصورة يجب أن لا يزيد عن 1 ميجابايت.",
			});
			toast.warning(" حجم الصورة يجب أن لا يزيد عن 1 ميجابايت.", {
				theme: "light",
			});
		} else {
			setImages(imageList);
			setDataError({ ...DataError, image: null });
		}
	};

	// Handle profile data
	const [editProfileData] = useEditProfileDataMutation();
	const handleUpdateProfile = async (data) => {
		setLoadingTitle("جاري تعديل الملف الشخصي");
		resetData();

		const formData = new FormData();
		formData.append("name", data?.name);
		formData.append("email", data?.email);
		formData.append("role", data?.user_type);
		formData.append("user_name", data?.user_name);
		formData.append("confirm_password", data?.confirm_password);
		if (data?.password) {
			formData.append("password", data?.password);
		}
		if (images.length > 0) {
			formData.append("image", images[0]?.file || null);
		}
		formData.append(
			"phonenumber",
			data?.phonenumber?.startsWith("+966") ||
				data?.phonenumber?.startsWith("00966")
				? data?.phonenumber
				: `+966${data?.phonenumber}`
		);

		try {
			const response = await editProfileData({
				body: formData,
			}).unwrap();

			if (
				response.data?.success === true &&
				response.data?.data?.status === 200
			) {
				setLoadingTitle("");
				setLoadingTitle(null);
				cancel();
				setEditUser();
			} else {
				setLoadingTitle(null);
				setDataError({
					name: response?.error?.data?.message?.en?.name?.[0],
					email: response?.error?.data?.message?.en?.email?.[0],
					phonenumber: response?.error?.data?.message?.en?.phonenumber?.[0],
					image: response?.error?.data?.message?.en?.image?.[0],
					user_name: response?.error?.data?.message?.en?.user_name?.[0],
					password: response?.error?.data?.message?.en?.password?.[0],
					confirm_password:
						response?.error?.data?.message?.en?.confirm_password?.[0],
					user_type: response?.error?.data?.message?.en?.user_type?.[0],
				});

				toast.error(
					response?.error?.data?.message?.ar
						? response.error.data.message.ar
						: response.error.data.message.en,
					{
						theme: "light",
					}
				);

				Object.entries(response?.error?.data?.message?.en)?.forEach(
					([key, message]) => {
						toast.error(message[0], { theme: "light" });
					}
				);
			}
		} catch (error) {
			console.error("Error changing editProfileData:", error);
		}
	};

	// handle paste phone number to handle remove space
	const handlePastePhoneNumber = (e) => {
		e.preventDefault();
		let pastedData = (e.clipboardData || window.clipboardData).getData("text");
		pastedData = pastedData.replace(/\s+/g, "");
		e.target.value = pastedData;
	};

	return (
		<>
			<Helmet>
				<title>لوحة تحكم أطلبها | الملف الشخصي</title>
			</Helmet>
			<BackDrop onClick={cancel}></BackDrop>
			<div
				className={`fixed bottom-0 left-0 bg-slate-50 z-20 otlobha_new_product ${styles.container}`}
				style={{
					width: "1104px",
					maxWidth: "100%",
					height: "calc(100% - 5rem)",
				}}>
				<form onSubmit={handleSubmit(handleUpdateProfile)} className='h-full'>
					<div className='flex flex-col justify-between h-full'>
						<div
							className='md:py-[48px] py-[30px] md:pr-[70px] pr-[20px]'
							style={{
								height: "135px",
								backgroundColor: "#EBEBEB",
							}}>
							{edit ? (
								<>
									<h2
										style={{ color: "#011723" }}
										className='md:text-[24px] text-[20px] font-bold'>
										تعديل بيانات حسابي
									</h2>
									<div className='flex flex-row mt-[10px]'>
										<h2
											style={{ color: "#011723" }}
											className='md:text-[18px] text-[12px] md:font-semibold font-normal ml-4'>
											حسابي الادمن
										</h2>

										<h3
											className='md:text-[18px] text-[12px] md:font-medium font-normal'
											style={{ color: "#7C7C7C" }}>
											/ تعديل بيانات الحساب
										</h3>
									</div>
								</>
							) : (
								<h2
									style={{ color: "#011723" }}
									className='md:text-[24px] text-[20px] font-bold'>
									حسابي الادمن
								</h2>
							)}
						</div>
						<div
							className={`flex-1 md:px-[90px] px-[18px] bg-[#F6F6F6] overflow-y-scroll py-[48px]  ${styles.content}`}>
							{loading ? (
								<CircularLoading />
							) : (
								<>
									<div className='flex flex-col flex-wrap items-center justify-between gap-6 md:flex-row md:items-start'>
										<div className='flex flex-col gap-5 md:flex-row'>
											<div className='md:h-44 md:w-44 h-[92px] w-[92px] self-center'>
												<img
													className='w-full h-full rounded-lg md:rounded-none'
													src={
														images?.length > 0
															? images[0].data_url
															: user?.image
													}
													alt='profile-img'
													loading='lazy'
												/>
											</div>
											<div className='flex flex-col md:gap-[8px] gap-4'>
												<h2
													style={{ fontSize: "20px", color: "#011723" }}
													className='font-medium text-center'>
													{user?.name}
												</h2>
												<div className='flex flex-col gap-[8px]'>
													<h2
														className='flex flex-row gap-[18px] items-center'
														style={{ fontSize: "18px", color: "#67747B" }}>
														<HiOutlineMail
															style={{
																cursor: "pointer",
																color: "rgba(29, 187, 190, 1)",
																fontSize: "1.5rem",
															}}></HiOutlineMail>
														{user?.email}
													</h2>
													<h2
														className='flex flex-row gap-[18px] items-center'
														style={{ fontSize: "18px", color: "#67747B" }}>
														<IoIosCall
															style={{
																cursor: "pointer",
																color: "rgba(29, 187, 190, 1)",
																fontSize: "1.5rem",
															}}></IoIosCall>
														<span
															style={{
																direction: "ltr",
															}}>
															{user?.phonenumber === ""
																? "لا يوجد رقم جوال"
																: user?.phonenumber}
														</span>
													</h2>
												</div>
											</div>
										</div>
										<Button
											style={{
												fontSize: "20px",
												color: "#EFF9FF",
												backgroundColor: edit ? "#02466A" : "#5EBFF2",
											}}
											type={"normal"}
											className='cursor-auto font-bold order-first md:order-last md:w-[278px] w-[162px] md:h-[60px] h-[48px]'>
											{user?.role?.name}
										</Button>
									</div>
									{edit && (
										<div className='flex md:flex-row flex-col mt-[52px] md:gap-48 gap-5'>
											<div className='flex flex-col flex-1 gap-5'>
												<div className='flex flex-col gap-2'></div>
												<div className='flex flex-col gap-2'>
													<label style={{ fontSize: "18px", color: "#011723" }}>
														الاسم<span className='text-red-500'>*</span>
													</label>
													<input
														className='w-full p-4 rounded-lg outline-none'
														style={{
															border: "none",
															backgroundColor: "#EBEBEB",
															color: "#011723",
														}}
														type='text'
														placeholder='ادخل حروف فقط'
														name='name'
														{...register("name", {
															required: "حقل الاسم مطلوب",
															pattern: {
																value: /^[^-\s][\u0600-\u06FF-A-Za-z ]+$/i,
																message: "يجب ان يتكون الاسم من أحرف",
															},
														})}
													/>
													<span className='text-red-500 text-[16px]'>
														{DataError?.name}
														{errors?.name && errors.name.message}
													</span>
												</div>
												<div className='flex flex-col gap-2'>
													<label style={{ fontSize: "18px", color: "#011723" }}>
														اسم المستخدم<span className='text-red-500'>*</span>
													</label>
													<input
														className='w-full p-4 rounded-lg outline-none'
														style={{
															border: "none",
															backgroundColor: "#EBEBEB",
															color: "#011723",
														}}
														type='text'
														placeholder='ادخل حروف فقط'
														name='user_name'
														{...register("user_name", {
															required: "حقل اسم المستخدم مطلوب",
															pattern: {
																value: /^[^-\s][a-zA-Z0-9_]+$/,
																message:
																	"حقل اسم المستخدم يجب ان يتكون من أحرف انجليزية",
															},
														})}
													/>
													<span className='text-red-500 text-[16px]'>
														{DataError?.user_name}
														{errors?.user_name && errors.user_name.message}
													</span>
												</div>
												<div className='flex flex-col gap-2'>
													<label style={{ fontSize: "18px", color: "#011723" }}>
														البريد الالكتروني
														<span className='text-red-500'>*</span>
													</label>
													<input
														className='w-full p-4 rounded-lg outline-none'
														style={{
															border: "none",
															backgroundColor: "#EBEBEB",
															color: "#011723",
														}}
														type='email'
														placeholder='sample@sa.com'
														name='email'
														{...register("email", {
															required: "حقل البريد الالكتروني مطلوب",
															pattern: {
																value: /\S+@\S+\.\S+/,
																message:
																	"صيغة البريد الالكتروني يجب ان تكون صحيحة",
															},
														})}
													/>
													<span className='text-red-500 text-[16px]'>
														{DataError?.email}
														{errors?.email && errors.email.message}
													</span>
												</div>
												<div className='flex flex-col gap-2'>
													<div className='flex flex-col'>
														<label
															style={{ fontSize: "18px", color: "#011723" }}>
															الصورة الشخصية
														</label>
														<span className='text-gray-400 font-light text-[14px]'>
															(الحد الأقصى للصورة 1MB)
														</span>
													</div>
													<ImageUploading
														value={images}
														onChange={onChangeImage}
														maxNumber={2}
														dataURLKey='data_url'
														acceptType={["jpg", "png", "jpeg"]}>
														{({ onImageUpload, dragProps }) => (
															// write your building UI
															<div
																className='relative flex items-center overflow-hidden rounded-lg upload__image-wrapper h-14'
																style={{
																	border: "none",
																	backgroundColor: "#EBEBEB",
																	color: "#011723",
																}}
																// onClick={() => {
																//   onImageUpload();
																// }}
																{...dragProps}>
																<h2
																	className='w-full p-4 outline-none cursor-pointer'
																	style={{
																		color: "#aaa",
																	}}
																	onClick={() => {
																		onImageUpload();
																	}}>
																	{images[0]?.file?.name || ""}
																</h2>
																<div
																	className='flex items-center justify-center h-full'
																	style={{
																		width: "3.5rem",
																		backgroundColor: "#02466A",
																	}}>
																	<img
																		src={UploadOutlined}
																		alt='upload-icon'
																		loading='lazy'
																	/>
																</div>
															</div>
														)}
													</ImageUploading>
													<span className='text-red-500 text-[16px]'>
														{DataError?.image}
													</span>
												</div>
											</div>
											<div className='flex flex-col flex-1 gap-5'>
												<div className='flex flex-col gap-2'>
													<label style={{ fontSize: "18px", color: "#011723" }}>
														رقم الجوال<span className='text-red-500'>*</span>
													</label>
													<div
														className='flex flex-row items-center gap-[1px] w-full p-4 rounded-lg'
														style={{
															backgroundColor: "#EBEBEB",
															color: "#011723",
															direction: "ltr",
														}}>
														<span>+966</span>
														<input
															className='w-full outline-none'
															style={{
																border: "none",
																backgroundColor: "transparent",
																direction: "ltr",
															}}
															type='tel'
															placeholder={500000000}
															name='phonenumber'
															onPaste={(e) => {
																handlePastePhoneNumber(e);
															}}
															maxLength={9}
															{...register("phonenumber", {
																required: "حقل  رقم الجوال مطلوب",
																pattern: {
																	value: /^(5\d{8})$/,
																	message:
																		"تأكد ان رقم الجوال يبدأ برقم 5 ولا يقل عن 9 أرقام",
																},
															})}
														/>
													</div>
													<span className='text-red-500 text-[16px]'>
														{DataError?.phonenumber}
														{errors?.phonenumber && errors.phonenumber.message}
													</span>
												</div>
												<div className='flex flex-col gap-2'>
													<label style={{ fontSize: "18px", color: "#011723" }}>
														كلمة المرور<span className='text-red-500'>*</span>
													</label>
													<div className='flex flex-row text-[#011723]  bg-[#EBEBEB] overflow-hidden items-center justify-between w-full p-4 rounded-lg outline-none'>
														<input
															className='w-full border-none text-[#011723]  bg-[#EBEBEB] pl-2 -mb-[2px] rounded-lg outline-none'
															style={{
																direction: "ltr",
															}}
															type={showPassword ? "text" : "password"}
															placeholder='.........'
															name='password'
															{...register("password", {})}
														/>
														{showPassword ? (
															<PasswordOpen
																onClick={() => setShowPassword(!showPassword)}
															/>
														) : (
															<PasswordClose
																onClick={() => setShowPassword(!showPassword)}
															/>
														)}
													</div>

													<p className='text-base font-normal text-[#ADB5B9]'>
														أدخل أرقام وحروف ورموز
													</p>
													<span className='text-red-500 text-[16px]'>
														{DataError?.password}
														{errors?.password && errors.password.message}
													</span>
												</div>
												<div className='flex flex-col gap-2'>
													<label style={{ fontSize: "18px", color: "#011723" }}>
														تأكيد كلمة المرور
														<span className='text-red-500'>*</span>
													</label>
													<div className='flex flex-row items-center text-[#011723]  bg-[#EBEBEB] overflow-hidden justify-between w-full p-4 rounded-lg outline-none'>
														<input
															className='w-full rounded-lg outline-none  border-none text-[#011723] bg-[#EBEBEB] pl-2 -mb-[2px]'
															style={{
																direction: "ltr",
															}}
															type={showPasswordConfirm ? "text" : "password"}
															placeholder='..........'
															name='confirm_password'
															{...register("confirm_password", {})}
														/>
														{showPasswordConfirm ? (
															<PasswordOpen
																className={styles.password}
																onClick={() =>
																	setShowPasswordConfirm(!showPasswordConfirm)
																}
															/>
														) : (
															<PasswordClose
																className={styles.password}
																onClick={() =>
																	setShowPasswordConfirm(!showPasswordConfirm)
																}
															/>
														)}
													</div>
													<p className='text-base font-normal text-[#ADB5B9]'>
														أدخل أرقام وحروف ورموز
													</p>
													<span className='text-red-500 text-[16px]'>
														{DataError?.confirm_password}
														{errors?.confirm_password &&
															errors.confirm_password.message}
													</span>
												</div>
											</div>
										</div>
									)}
								</>
							)}
						</div>
						<div className='py-8 md:px-8 px-[18px] flex justify-center gap-4 md:h-[135px] h-[115px] md:bg-[#EBEBEB] bg-[#F6F6F6]'>
							{!edit && (
								<div className='flex flex-row justify-center w-full gap-5'>
									<Button
										style={{
											backgroundColor: "#02466A",
											borderColor: `#02466A`,
										}}
										textStyle={{ color: "#EFF9FF" }}
										className='md:w-[128px] w-full md:h-[56px] h-[50px] md:text-[24px] text-[19px] font-medium'
										type={"normal"}
										onClick={setEditUser}>
										تعديل
									</Button>
									<Button
										style={{
											width: "128px",
											height: "56px",
											borderColor: `#02466A`,
										}}
										textStyle={{ color: "#02466A" }}
										className='md:w-[128px] w-full md:h-[56px] h-[50px] md:text-[24px] text-[19px] font-medium'
										type={"outline"}
										onClick={cancel}>
										اغلاق
									</Button>
								</div>
							)}
							{edit && (
								<>
									<Button
										btnType={"submit"}
										style={{
											border: "1px solid #02466A",
											borderRadius: "8px",
											backgroundColor: "#02466A",
										}}
										className='md:w-[182px] w-full md:h-[56px] h-[50px] md:text-[24px] text-[19px] font-medium '
										textStyle={{ color: "#fff" }}
										type={"outline"}>
										حفظ
									</Button>
									<Button
										style={{
											width: "128px",
											height: "56px",
											borderColor: `#02466A`,
										}}
										textStyle={{ color: "#02466A" }}
										className='md:w-[128px] w-full md:h-[56px] h-[50px] md:text-[24px] text-[19px] font-medium'
										type={"outline"}
										onClick={() => {
											cancel();
											setEditUser();
										}}>
										اغلاق
									</Button>
								</>
							)}
						</div>
					</div>
				</form>
			</div>
		</>
	);
};

export default MyAccount;
